import "@/styles/globals.scss";
import type { AppProps } from "next/app";
import { useAppStore } from "@/store";
import { useEffect } from "react";
import { SessionProvider } from "next-auth/react";
import { NextSeo } from "next-seo";
import Script from "next/script";
import { ToastContainer } from "react-toastify";
import 'react-toastify/scss/main.scss';

export default function App({ Component, pageProps }: AppProps) {
  const { loadAccounts } = useAppStore();
  const GA_MEASUREMENT_ID = process.env.NEXT_PUBLIC_ANALYTICS_ID;

  useEffect(() => {
    loadAccounts();
  }, []);
  return (
    <SessionProvider session={pageProps.session}>
      <NextSeo
        title="Streamon - Instagram Live Streaming with OBS, XSplit, Restream and other broadcasting softwares"
        description="Go live on Instagram from your desktop via OBS, XSplit, vMix, Restream, Wirecast, VidBlasterX, StreamYard"
        canonical="https://getstreamon.com/"
        openGraph={{
          url: "https://getstreamon.com",
          title:
            "Streamon - Instagram Live Streaming with OBS, XSplit, Restream and other broadcasting softwares",
          description:
            "Go live on Instagram from your desktop via OBS, XSplit, vMix, Restream, Wirecast, VidBlasterX, StreamYard",
          images: [{ url: "https://app.getstreamon.com/cover.png" }],
          siteName: "Streamon",
        }}
        twitter={{
          handle: "@streamonhq",
          site: "https://app.getstreamon.com",
          cardType: "summary_large_image",
        }}
      />
      <Script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`}
      ></Script>
      <Script>
        {`
window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());

gtag('config', '${GA_MEASUREMENT_ID}');
`}
      </Script>
      <Component {...pageProps} />
      <ToastContainer
        autoClose={5000}
        position="bottom-center"
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </SessionProvider>
  );
}
