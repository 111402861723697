import { create } from "zustand";
import { IGAccountsStore, createIGAccountsStore } from "./AccountsStore";
import { mountStoreDevtool } from "simple-zustand-devtools";

type StoreState = IGAccountsStore;

export const useAppStore = create<StoreState>()((...a) => ({
  ...createIGAccountsStore(...a),
}));

if (process.env.NODE_ENV === "development") {
  mountStoreDevtool("Store", useAppStore);
}