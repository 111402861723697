export const IG_IMAGE_PROXY_URL = process.env.NEXT_PUBLIC_IG_CLOUDFLARE_PROXY;

// local storage keys
export const STORAGE_KEYS = {
  SAVED_ACCOUNTS: "SAVED_ACCOUNTS",
};

export const COMMENT_SYNC_INTERVAL = 60000;
export const HEART_BEAT_INTERVAL = 60000;
export const MAX_COMMENT_LOAD_COUNT = 100;

// demo
export const MAX_ACCOUNTS = 2;
export const REQUEST_UPGRADE_LINK = "https://forms.gle/quJ9HenrePPR2vFC7";
