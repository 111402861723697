import { MAX_ACCOUNTS, STORAGE_KEYS } from "@/utils/constants";
import { AccountRepositoryCurrentUserResponseUser } from "streamon-instagram-private-api";
import { StateCreator } from "zustand";

export interface IGAccount {
  username: string;
  profile: AccountRepositoryCurrentUserResponseUser;
  session: any;
}

export type IGAccountsObject = {
  [key: string]: IGAccount
}

export interface LiveStream {
  isLive: boolean;
  start_time: number;
  end_time: number;
  username: string;
  broadcast_id: string;
  upload_url: string;
  stream_key: string;
  stream_url: string;
  broadcaster_update_frequency: number;
  heartbeat_interval: number;
  max_time_in_seconds: number;
  stream_video_fps: number;
  stream_audio_bit_rate: number;
  stream_video_bit_rate: number;
}

export interface IGAccountsStore {
  accounts: { [key: string]: IGAccount };
  activeLiveStreams: { [key: string]: LiveStream };
  addAccount: (account: IGAccount) => void;
  removeAccount: (username: string) => void;
  addLiveStream: (ls: LiveStream) => void;
  getAccountByUsername: (username: string) => IGAccount;
  getStreamByUsername: (username: string) => LiveStream;
  clearData: () => void;
  loadAccounts: () => void;
}

export const createIGAccountsStore: StateCreator<IGAccountsStore> = (
  set,
  get
) => ({
  accounts: {},
  activeLiveStreams: {},
  addAccount: async (account: IGAccount) => {
    const accounts = get().accounts;
    const updatedAccounts = { ...accounts, [account.username]: account };
    localStorage.setItem(
      STORAGE_KEYS.SAVED_ACCOUNTS,
      JSON.stringify(updatedAccounts)
    );
    set({ accounts: updatedAccounts });
  },
  removeAccount: async (username: string) => {
    const accounts = get().accounts;
    delete accounts[username];
    localStorage.setItem(STORAGE_KEYS.SAVED_ACCOUNTS, JSON.stringify(accounts));
    set({ accounts });
  },
  getStreamByUsername: (username: string) => {
    const liveStreams = get().activeLiveStreams;
    return liveStreams[username];
  },
  getAccountByUsername: (username: string) => {
    const accounts = get().accounts;
    return accounts[username];
  },
  addLiveStream: async (ls: LiveStream) => {
    const activeLiveStreams = get().activeLiveStreams;
    set({ activeLiveStreams: { ...activeLiveStreams, [ls.username]: ls } });
  },
  clearData: async () => {
    set({ activeLiveStreams: {}, accounts: {} });
    localStorage.removeItem(STORAGE_KEYS.SAVED_ACCOUNTS);
  },
  loadAccounts: async () => {
    try {
      const accountsData = localStorage.getItem(STORAGE_KEYS.SAVED_ACCOUNTS);
      if (accountsData) {
        const accounts: IGAccountsObject = JSON.parse(accountsData);
        if (Object.keys(accounts).length > MAX_ACCOUNTS) {
          Object.keys(accounts)
            .slice(0, MAX_ACCOUNTS)
            .reduce<IGAccountsObject>(
              (result, username) => ({
                [username]: accounts[username],
                ...result,
              }),
              {} as IGAccountsObject
            );
        } else {
          set({ accounts });
        }
      } else {
        console.log(`No saved accounts found`);
      }
    } catch (error) {
      console.error(error);
    }
  },
});
